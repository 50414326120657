import React from "react";
import { connect } from "react-redux";
import CardImageAndDetail from "./CardImageAndDetail";
import CardDetail from "./CardDetail";
const CardCorrugatedPaperGrades = ({ text, imageCa, imageKs, imageKi, imageKa, imageKl }) => {
  return (
    <div className="card card-collapse">
      <div className="card-header card-collapse-header" id="headingTwo">
        <h5 className="mb-0">
          <button
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            <b>{text["about.collapse.corrugated-paper-grades"]}</b>
            <span>+</span>
          </button>
        </h5>
      </div>
      <div
        id="collapseTwo"
        className="collapse"
        aria-labelledby="headingTwo"
        data-parent="#accordion"
      >
        <div className="card-body card-collapse-body">
          <CardImageAndDetail
            header={text["about.collapse.ca-or-m"]}
            detail={text["about.collapse.ca-or-m.detail"]}
            urlImage={imageCa}
          />
          <CardDetail
            header={text["about.collapse.usability"]}
            detail={text["about.collapse.ca-or-m.usability.detail"]}
          />
          <CardImageAndDetail
            header={text["about.collapse.ks-or-kw"]}
            detail={text["about.collapse.ks-or-kw.detail"]}
            urlImage={imageKs}
          />
          <CardDetail
            header={text["about.collapse.usability"]}
            detail={text["about.collapse.ks-or-kw.usability.detail"]}
          />
          <CardImageAndDetail
            header={text["about.collapse.ki"]}
            detail={text["about.collapse.ki.detail"]}
            urlImage={imageKi}
          />
          <CardDetail
            header={text["about.collapse.usability"]}
            detail={text["about.collapse.ki.usability.detail"]}
          />
          <CardImageAndDetail
            header={text["about.collapse.ka"]}
            detail={text["about.collapse.ka.detail"]}
            urlImage={imageKa}
          />
          <CardDetail
            header={text["about.collapse.usability"]}
            detail={text["about.collapse.ka.usability.detail"]}
          />
          <CardImageAndDetail
            header={text["about.collapse.kl"]}
            detail={text["about.collapse.kl.detail"]}
            urlImage={imageKl}
          />
          <CardDetail
            header={text["about.collapse.usability"]}
            detail={text["about.collapse.kl.usability.detail"]}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
CardCorrugatedPaperGrades.defaultProps = {
  imageCa: require("../../assets/img/ca.jpg"),
  imageKs: require("../../assets/img/ks.jpg"),
  imageKi: require("../../assets/img/ki.jpg"),
  imageKa: require("../../assets/img/ka.jpg"),
  imageKl: require("../../assets/img/kl.jpg")
};
export default connect(mapStateToProps, {})(CardCorrugatedPaperGrades);
