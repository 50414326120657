module.exports = {
    "navbar.home": "ホーム",
    "navbar.about": "企業",
    "navbar.product": "製品",
    "navbar.certificate": "企業理念",
    "navbar.contact-us": "お問い合わせ",
    "home.welcome": "J.T.P Co.、Ltdへようこそ。",
    "home.detail": "BEYOND PACKAGING",
    "welcome.header": "J.T.P Co.、Ltdへようこそ。",
    "welcome.Introduction": `J.T.P. (1991) Co., Ltd. は段ボール箱加工業として約20年の経験と実績を持つ企業です。多様化する顧客ニーズにお応えするために、企業の業績を伸ばし、また業務拡大をしつつ、製品の品質と精度を高めてきました。私達はISO9001:2008を取得し、2016年に新しい工場を立ち上げ、稼働に至っており、その生産力は一日何万枚もの高品質の段ボールや紙パレットや型抜きボックスやエッジボードなどを加工を可能にしています。`,
    "about.header": '企業',
    "about.skill-part1": "私たちは",
    "about.skill-part2": "包装製造",
    "about.about-detail": `J.T.P. (1991) Co., Ltd. は段ボール箱加工業として約20年の経験と実績を持つ企業です。多様化する顧客ニーズにお応えするために、企業の業績を伸ばし、また業務拡大をしつつ、製品の品質と精度を高めてきました。私達はISO9001:2015を取得し、2016年に新しい工場を立ち上げ、稼働に至っており、その生産力は一日何万枚もの高品質の段ボールや紙パレットや型抜きボックスやエッジボードなどを加工を可能にしています。`,
    "corrugated.header": "段ボール",
    "corrugated.detail": `弊社は様々なタイプの段ボールを取り扱っています。例えば、四つ種類のライナーや四つ種類の中芯などです。`,
    "about.collapse.corrugated-paper-types": "段ボールについて",
    "about.collapse.single-facer-board": "片面ボード",
    "about.collapse.single-facer-board.detail": `製品が破損または揺れないように、片面ライナーボードで包みます。これはよく一般的に使われています。構成1枚のライナーに波形状に加工した中心を貼り合わせたボード。`,
    "about.collapse.single-wall-board": "両面ボード",
    "about.collapse.single-wall-board.detail": `インナーボックスに使用するためや、型抜きの箱や下敷などに使われます。構成片面ボードの段頂にライナーを貼り合わせたボード。`,
    "about.collapse.double-wall-board": "複両面ボード",
    "about.collapse.double-wall-board.detail": `揺れないように、複両面ライナーボードで防ぎます。例えば、RSCという段ボールです。重量のある製品を運搬するためや特別な頑丈な箱などにアウトターボックスとして使用します。構成両面ボードの片面に片面ボードを貼り合わせたボード。`,
    "about.collapse.corrugated-paper-grades": "クラフトのグレード",
    "about.collapse.ca-or-m": "CA または M",
    "about.collapse.ca-or-m.detail": `CAまたはM弾力性があるので、衝撃を防ぎます。様々なものを入れるのに適応します。これは他のクラフトと比べて、安価です。`,
    "about.collapse.usability": "使い方",
    "about.collapse.ca-or-m.usability.detail": `例えば、引越しの時にこの種類の段ボールを使うと、中の物が壊れにくくなります。それはこのタイプのクラフトを使用しているからです。標準の重さは125グラムです。`,
    "about.collapse.ks-or-kw": "KS または KW",
    "about.collapse.ks-or-kw.detail": `KS またはKW箱の外面を覆うためのクラフトです。このグレードは紙の外面が白く、弾力性と美しさが特別で、このクラフトを使うと高級感のある外見になります。弾力性はCAやMよりも（やや）強いです。`,
    "about.collapse.ks-or-kw.usability.detail": `特にこのパッケージは野菜や果物や花などを輸出するのに人気があります。さらに、電気製品の運搬や冷凍食品の運搬にもよく使われます。このクラフトの価格は少し高めです。標準の重さは170グラムです。`,
    "about.collapse.ki": "KI125と150と 185",
    "about.collapse.ki.detail": `箱の外面を作るためのクラフトです。このグレードは弾力性と耐久性は標準です。国内商品の運送向けです。`,
    "about.collapse.ki.usability.detail": `消耗品や電気製品やガラス製品を運搬するのに役に立ちます。標準の重さは１２５、１５０、１８５グラムです。`,
    "about.collapse.ka": "KI125 と150と185と230",
    "about.collapse.ka.detail": `箱の外面を作るためのクラフトです。外面の色は濃い黄土色です。弾力性と耐久性と防温性がありますが、あまり高価ではありません。商品の輸出のパッケージに人気があります。`,
    "about.collapse.ka.usability.detail": `全ての商品のパッケージで使用可能です。重い物を遠い所に送ることにも適しています。さらに、箱を積み重ねても大丈夫です。例えば、車部品や消耗品や電気回路なども入れられます。標準の重さは１２５、１５０、１８５、２３０グラムです。`,
    "about.collapse.kl": "KL125 と150と175と205",
    "about.collapse.kl.detail": `箱の表面を作るためのクラフトです。表面の色は茶色です。カラートーンは外国クラフトと同じで、国際的に受け入れています。このグレードはKAより、弾力性において劣ります。KIとKKグレードのカラートーンと似ています。`,
    "about.collapse.kl.usability.detail": `各種外国商品の運送向け段ボールです。標準の重さは120、150、175、205グラムです。`,
    "about.collapse.corrugated-types": "中芯（ナミナミ）の種類",
    "about.collapse.e-flute": "E フルート",
    "about.collapse.e-flute.detail-part1": `ナミナミの数 : 29 – 30 / 10 センチ`,
    "about.collapse.e-flute.detail-part2": `高さ : 0.15 センチ`,
    "about.collapse.e-flute.usability.detail": `小さい箱やカラープリント箱などによく使われます。見た目がとてもきれいです。例えば、子供の玩具を入れる箱や電気製品を入れる箱などです。`,
    "about.collapse.b-flute": "B フルート",
    "about.collapse.b-flute.detail-part1": `ナミナミの数 : 15 – 16 / 10 センチ`,
    "about.collapse.b-flute.detail-part2": `高さ : 0.25　センチ`,
    "about.collapse.usability.b-flute.detail": `ナミナミの類型はCより小さいです。とても軽量、小形な物の梱包箱に主に使われます。`,
    "about.collapse.c-flute": "C フルート",
    "about.collapse.c-flute.detail-part1": "ナミナミの数 : 13－14 / 10 センチ",
    "about.collapse.c-flute.detail-part2": "高さ : 0.35　センチ",
    "about.collapse.c-flute.usability.detail": `一番大きい中芯(ナミナミ)です。外装箱用途の段ボールに最もよく使われる材質です。`,
    "about.collapse.bc-flute": "BC フルート",
    "about.collapse.bc-flute.detail-part1": `ナミナミの数 : 15 – 16 / 10 センチと13 – 14 / 10センチ`,
    "about.collapse.bc-flute.detail-part2": `高さ : 0.25 センチと0.35センチ`,
    "about.collapse.bc-flute.usability.detail": `文字通りBとCを重ねた材質で、重量物などの箱に多く使われる段ボールの材質です。`,
    "our-product.header": "製品紹介",
    "our-product.detail": `J.T.P. (1991) Co., Ltd. は段ボール箱加工業として約20年の経験と実績を持つ企業です。多様化する顧客ニーズにお応えするために、企業の業績を伸ばし、また業務拡大をしつつ、製品の品質と精度を高めてきました。私達はISO9001:2015を取得し、2016年に新しい工場を立ち上げ、稼働に至っており、その生産力は一日何万枚もの高品質の段ボールや紙パレットや型抜きボックスやエッジボードなどを加工を可能にしています。`,
   "our-product.carton-box": "段ボール",
   "our-product.paper-pallet": "紙パレット",
   "our-product.paper-tube": "紙芯",
   "our-product.paper-corner": "ペーパーコーナー",
   "our-product.die-cut-box": "上下差込式",
   "our-product.die-cut-box2": "B式段ボール箱",
   "our-product.die-cut-box3": "B式段ボール箱",
   "our-product.die-cut-box4": "B式段ボール箱",
   "our-product.partition": "パーティション",
   "our-product.paper-core": "紙管",
   "our-product.regular-slotted-container-rsc": "A式段ボール箱",
   "our-product.half-slotted-container-hsc": "C式段ボール箱",
   "our-product.snap-bottom-containers-with-rsc-top": "上差込下組式",
   "our-product.pizza-style": "N式サイド差し込み式",
   "our-product.cover-box": "C式段ボール箱",
   "our-product.carton-box-with-paper-pallet": "段ボール箱と紙パレット",
   "our-product.support-box": "サポート",
   "our-product.subcaption-rsc": "通常のスロット付きコンテナ",
   "our-product.subcaption-ftd": "フルテレスコープデザインスタイルボックス",
   "our-product.subcaption-die-cut": "ダイカットボックス",
   "our-product.subcaption-die-cut-box": "上下差込式",
   "our-product.subcaption-partition-pad": "パーティション、パッド",
   "our-product.subcaption-hand-made": "手作り",
   "our-product.subcaption-bigbox": "大型木パレット",
   "our-product.subcaption-paper-pallet": "紙パレット",
   "our-product.subcaption-paper-tube": "紙芯",
   "our-product.subcaption-paper-corner": "ペーパーコーナー",
   "certificate.header": "企業理念",
   "certificate.detail": `ISO9001とは、Quality Management System (QMS) 品質マネジメントシステムの準備規格であり、ISO (国際準備)として認定されている規格です。ISO9001の目的は、品質の維持向上するために組織がどのような体制を構築し、実現していけばよいのかを準備化し、どのような業界でも取り組めるように実施基準とすることを狙いとして制定されています`,
   "certificate.iso": "ISO 9001: 2015 は、品質マネジメント８原則に基づいています。",
   "certificate.upper-customer-focus": "顧客重視",
   "certificate.upper-leadership": "リーダーシップ",
   "certificate.upper-system-approach": "人々の参画",
   "certificate.upper-continual-improvement": "プロセスアプローチ",
   "certificate.upper-involvement-of-people": "マネジメントへのシステムアプローチ",
   "certificate.upper-process-approach": "継続的改善",
   "certificate.upper-fact-based-decision-making": "意思決定への事実に基づくアプローチ",
   "certificate.upper-mutually-beneficial-supplier-relationship": "供給者との互恵関係",
   "contact.header": "お問い合わせ",
   "contact.firstname-*": "ファーストネーム *",
   "contact.please-enter-your-firstname": "名を入力してください",
   "contact.lastname-*": "苗字 *",
   "contact.please-enter-your-lastname": "あなたの姓を入力",
   "contact.email-*": "Eメール *",
   "contact.please-enter-your-email-address": "メールアドレスを入力してください",
   "contact.please-enter-a-valid-email-address": "有効なメールアドレスを入力してください",
   "contact.tel-*": "電話 *",
   "contact.please-enter-your-tel": "電話番号を入力してください",
   "contact.description-*": "説明 *",
   "contact.please-write-your-description": "説明を書いてください",
   "contact.submit": "送信する",
   "contact.send-contact-success": "成功を送る",
   "contact.send-contact-fail": "伝送障害",
   "footer.tel": "Tel. 02-337-6644-46 #121-122",
   "footer.address": "33/2 Moo.1 Bangna - Trad Rd. KM.33, Bangpleenoi, Bangbo, Samutprakarn 10560",
   "footer.copyright": "Copyright © 2021 All Rights Reserved J.T.P (1991) CO., LTD."
  };