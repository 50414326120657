import React from "react";
import { connect } from "react-redux";
import CardImageAndDetail from "./CardImageAndDetail";
const CardCorrugatedPaper = ({ text, sectionImage1, sectionImage2, sectionImage3 }) => {
  return (
    <div className="card card-collapse">
      <div className="card-header card-collapse-header" id="headingOne">
        <h5 className="mb-0">
          <button
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <b>{text["about.collapse.corrugated-paper-types"]}</b>
            <span>+</span>
          </button>
        </h5>
      </div>

      <div
        id="collapseOne"
        className="collapse"
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body card-collapse-body">
          <CardImageAndDetail
            header={text["about.collapse.single-facer-board"]}
            detail={text["about.collapse.single-facer-board.detail"]}
            urlImage={sectionImage1}
          />
          <CardImageAndDetail
            header={text["about.collapse.single-wall-board"]}
            detail={text["about.collapse.single-wall-board.detail"]}
            sideImage={"left"}
            urlImage={sectionImage2}
          />
          <CardImageAndDetail
            header={text["about.collapse.double-wall-board"]}
            detail={text["about.collapse.double-wall-board.detail"]}
            urlImage={sectionImage3}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
CardCorrugatedPaper.defaultProps = {
  sectionImage1: require("../../assets/img/single-facer-board.jpg"),
  sectionImage2: require("../../assets/img/single-wall-board.jpg"),
  sectionImage3: require("../../assets/img/double-wall-board.jpg")
};
export default connect(mapStateToProps, {})(CardCorrugatedPaper);
