import React from "react";
import { connect } from "react-redux";
import CardImageAndDetail from "./CardImageAndDetail";
import CardDetail from "./CardDetail";
const CardCorrugatedTypes = ({ text, imageEcurve, imageBcurve, imageCcurve, imageBCcurve }) => {
  return (
    <div className="card card-collapse">
      <div className="card-header card-collapse-header" id="headingThree">
        <h5 className="mb-0">
          <button
            data-toggle="collapse"
            data-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            <b>{text["about.collapse.corrugated-types"]}</b>
            <span>+</span>
          </button>
        </h5>
      </div>
      <div
        id="collapseThree"
        className="collapse"
        aria-labelledby="headingThree"
        data-parent="#accordion"
      >
        <div className="card-body card-collapse-body">
          <CardImageAndDetail
            header={text["about.collapse.e-flute"]}
            detail={
              <span>
                {text["about.collapse.e-flute.detail-part1"]}
                <br />
                <br />
                {text["about.collapse.e-flute.detail-part2"]}
              </span>
            }
            urlImage={imageEcurve}
          />
          <CardDetail
            header={text["about.collapse.usability"]}
            detail={text["about.collapse.e-flute.usability.detail"]}
          />
          <CardImageAndDetail
            header={text["about.collapse.b-flute"]}
            detail={
              <span>
                {text["about.collapse.b-flute.detail-part1"]}
                <br />
                <br />
                {text["about.collapse.b-flute.detail-part2"]}
              </span>
            }
            urlImage={imageBcurve}
          />
          <CardDetail
            header={text["about.collapse.usability"]}
            detail={text["about.collapse.usability.b-flute.detail"]}
          />

          <CardImageAndDetail
            header={text["about.collapse.c-flute"]}
            detail={
              <span>
                {text["about.collapse.c-flute.detail-part1"]}
                <br />
                <br />
                {text["about.collapse.c-flute.detail-part2"]}
              </span>
            }
            urlImage={imageCcurve}
          />
          <CardDetail
            header={text["about.collapse.usability"]}
            detail={text["about.collapse.c-flute.usability.detail"]}
          />

          <CardImageAndDetail
            header={text["about.collapse.bc-flute"]}
            detail={
              <span>
                {text["about.collapse.bc-flute.detail-part1"]}
                <br />
                <br />
                {text["about.collapse.bc-flute.detail-part2"]}
              </span>
            }
            urlImage={imageBCcurve}
          />
          <CardDetail
            header={text["about.collapse.usability"]}
            detail={text["about.collapse.bc-flute.usability.detail"]}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
CardCorrugatedTypes.defaultProps = {
  imageEcurve: require("../../assets/img/Ecurve.jpg"),
  imageBcurve: require("../../assets/img/Bcurve.jpg"),
  imageCcurve: require("../../assets/img/Ccurve.jpg"),
  imageBCcurve: require("../../assets/img/BCcurve.jpg")
};
export default connect(mapStateToProps, {})(CardCorrugatedTypes);
