import * as actionTypes from './actionTypes';
import dataEN from "../../locales/en_US";
import dataTH from "../../locales/th_TH";
import dataJP from "../../locales/jp_JP";
const initialState = {
    language: 'en',
    text: dataEN,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_LANGUAGE:
            let setData = {};
            switch (action.language) {
                case actionTypes.EN:
                    setData = dataEN;
                    break;
                case actionTypes.TH:
                    setData = dataTH;
                    break;
                case actionTypes.JP:
                    setData = dataJP;
                    break;
                default:
                    break;
            }
            return { ...state, language: action.language, text: setData };

        default: return { ...state };
    }
};