import React, { Component } from "react";
//Components List
import NavBar from "../components/NavBar";
import BannerOne from "../components/banner/BannerOne";
import Welcome from "../components/Welcome";
import About from "../components/About";
import Services from "../components/Services";
import ContactUs from "../components/ContactUS";
import Footer from "../components/Footer";
import ScrollUpBtn from "../components/ScrollUpBtn";
import Certificate from "../components/Certificate";
class HomeOne extends Component {
  render() {
    return (
      <React.Fragment>
        {/* NavBar: src/components/NavBar */}
        <NavBar />
        {/* NavBar: src/components/banner/BannerOne */}
        <BannerOne />
        {/* Welcome: src/components/Welcome */}
        <Welcome />
        {/* About: src/components/About */}
        <About />
        {/* Services: src/components/Services */}
        <Services />
        <Certificate />
        {/* News: src/components/News */}
        <ContactUs />
        {/* Footer: src/components/Footer */}
        <Footer />
        {/* ScrollUpBtn: src/components/ScrollUpBtn */}
        <ScrollUpBtn />
      </React.Fragment>
    );
  }
}
export default HomeOne;
