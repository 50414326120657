import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactBnbGallery from "react-bnb-gallery";
import "react-bnb-gallery/dist/style.css";
import OwlCarousel from "react-owl-carousel3";

const Services = ({
  text,
  untitled_37,
  untitled_38,
  untitled_39,
  untitled_40,
  untitled_49,
  untitled_50,
  untitled_46,
  untitled_47,
  untitled_48,
  untitled_116,
  untitled_117,
  untitled_118,
  untitled_121,
  untitled_122,
  untitled_119,
  untitled_120,
  untitled_137,
  untitled_139,
  untitled_140,
  untitled_141,
  untitled_142,
  untitled_8,
  untitled_9,
  untitled_10,
  untitled_11,
  untitled_12,
  untitled_3,
  untitled_5,
  untitled_6,
  untitled_7,
  untitled_54,
  untitled_53,
  untitled_52,
  untitled_30,
  untitled_29,
  untitled_28,
  untitled_27,
  untitled_26,
  untitled_19,
  untitled_18,
  untitled_17,
  untitled_16,
  untitled_15,
  untitled_14,
  untitled_13,
  untitled_103,
  untitled_104,
  untitled_105,
  untitled_106,
  untitled_107,
  untitled_108,
  untitled_109,
  untitled_111,
  untitled_112,
  untitled_55,
  untitled_56,
  untitled_57,
  untitled_58,
  untitled_59,
  untitled_61,
  untitled_62,
  untitled_63,
  untitled_64,
  untitled_65,
  untitled_66,
  untitled_67,
  untitled_68,
  untitled_69,
  untitled_70,
  untitled_71,
  untitled_72,
  untitled_73,
  untitled_74,
  untitled_75,
  untitled_76,
  untitled_77,
  untitled_78,
  untitled_79,
  untitled_80,
  untitled_81,
  untitled_82,
  untitled_83,
  untitled_84,
  untitled_85,
  untitled_86,
  untitled_87,
  untitled_88,
  untitled_89,
  untitled_90,
  untitled_91,
  untitled_92,
  untitled_93,
  untitled_94,
  untitled_95,
  untitled_96,
  untitled_97,
  untitled_98,
  untitled_99,
  untitled_100,
  untitled_41,
  untitled_42,
  untitled_43,
  untitled_44,
  untitled_45,
  untitled_115,
  untitled_114,
  untitled_113,
  untitled_31,
  untitled_32,
  untitled_33,
  untitled_34,
  untitled_35,
  untitled_36
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photo, setPhoto] = useState([]);
  const servicesData = [
    // new
    {
      photo: untitled_37,
      caption: text["our-product.die-cut-box"],
      data: [
        {
          photo: untitled_37,
          caption: text["our-product.die-cut-box"],
          subcaption: text["our-product.subcaption-die-cut-box"],
        },
        {
          photo: untitled_38,
          caption: text["our-product.die-cut-box"],
          subcaption: text["our-product.subcaption-die-cut-box"],
        },
        {
          photo: untitled_39,
          caption: text["our-product.die-cut-box"],
          subcaption: text["our-product.subcaption-die-cut-box"],
        }, {
          photo: untitled_40,
          caption: text["our-product.die-cut-box"],
          subcaption: text["our-product.subcaption-die-cut-box"],
        }, {
          photo: untitled_49,
          caption: text["our-product.die-cut-box"],
          subcaption: text["our-product.subcaption-die-cut-box"],
        }, {
          photo: untitled_50,
          caption: text["our-product.die-cut-box"],
          subcaption: text["our-product.subcaption-die-cut-box"],
        }
      ],
    },
    {
      photo: untitled_46,
      caption: text[ "our-product.pizza-style"],
      data: [
        {
          photo: untitled_46,
          caption: text[ "our-product.pizza-style"],
          subcaption: text[ "our-product.pizza-style"],
        },
        {
          photo: untitled_47,
          caption: text[ "our-product.pizza-style"],
          subcaption: text[ "our-product.pizza-style"],
        },
        {
          photo: untitled_48,
          caption: text[ "our-product.pizza-style"],
          subcaption: text[ "our-product.pizza-style"],
        }
      ]
    },
    {
      photo: untitled_116,
      caption: text["our-product.cover-box"],
      data: [
        {
          photo: untitled_116,
          caption: text["our-product.cover-box"],
          subcaption: text["our-product.cover-box"],
        },
        {
          photo: untitled_117,
          caption: text["our-product.cover-box"],
          subcaption: text["our-product.cover-box"],
        },
        {
          photo: untitled_118,
          caption: text["our-product.cover-box"],
          subcaption: text["our-product.cover-box"],
        }
        ,
        {
          photo: untitled_121,
          caption: text["our-product.cover-box"],
          subcaption: text["our-product.cover-box"],
        }
        ,
        {
          photo: untitled_122,
          caption: text["our-product.cover-box"],
          subcaption: text["our-product.cover-box"],
        }
      ]
    },
    {
      photo: untitled_119,
      caption: text["our-product.support-box"],
      data: [
        {
          photo: untitled_119,
          caption: text["our-product.support-box"],
          subcaption: text["our-product.support-box"],
        },
        {
          photo: untitled_120,
          caption: text["our-product.support-box"],
          subcaption: text["our-product.support-box"],
        }
      ]
    },
    {
      photo: untitled_137,
      caption: text["our-product.carton-box-with-paper-pallet"],
      data: [
        {
          photo: untitled_137,
          caption: text["our-product.carton-box-with-paper-pallet"],
          subcaption: text["our-product.carton-box-with-paper-pallet"],
        },
        {
          photo: untitled_139,
          caption: text["our-product.carton-box-with-paper-pallet"],
          subcaption: text["our-product.carton-box-with-paper-pallet"],
        },
        {
          photo: untitled_140,
          caption: text["our-product.carton-box-with-paper-pallet"],
          subcaption: text["our-product.carton-box-with-paper-pallet"],
        },
        {
          photo: untitled_141,
          caption: text["our-product.carton-box-with-paper-pallet"],
          subcaption: text["our-product.carton-box-with-paper-pallet"],
        },
        {
          photo: untitled_142,
          caption: text["our-product.carton-box-with-paper-pallet"],
          subcaption: text["our-product.carton-box-with-paper-pallet"],
        }
      ]
    },
    {
      photo: untitled_8,
      caption: text["our-product.die-cut-box2"],
      data: [
        {
          photo: untitled_8,
          caption: text["our-product.die-cut-box2"],
          subcaption: text["our-product.die-cut-box2"],
        },
        {
          photo: untitled_9,
          caption: text["our-product.die-cut-box2"],
          subcaption: text["our-product.die-cut-box2"],
        },
        {
          photo: untitled_10,
          caption: text["our-product.die-cut-box2"],
          subcaption: text["our-product.die-cut-box2"],
        },
        {
          photo: untitled_11,
          caption: text["our-product.die-cut-box2"],
          subcaption: text["our-product.die-cut-box2"],
        },
        {
          photo: untitled_12,
          caption: text["our-product.die-cut-box2"],
          subcaption: text["our-product.die-cut-box2"],
        }
      ]
    },
    {
      photo: untitled_3,
      caption: text["our-product.die-cut-box3"],
      data: [
        {
          photo: untitled_3,
          caption: text["our-product.die-cut-box3"],
          subcaption: text["our-product.die-cut-box3"],
        },
        {
          photo: untitled_5,
          caption: text["our-product.die-cut-box3"],
          subcaption: text["our-product.die-cut-box3"],
        },
        {
          photo: untitled_6,
          caption: text["our-product.die-cut-box3"],
          subcaption: text["our-product.die-cut-box3"],
        },
        {
          photo: untitled_7,
          caption: text["our-product.die-cut-box3"],
          subcaption: text["our-product.die-cut-box3"],
        }
      ]
    },
    {
      photo: untitled_13,
      caption: text["our-product.snap-bottom-containers-with-rsc-top"],
      data: [
        {
          photo: untitled_13,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_14,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_15,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_16,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_17,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_18,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_19,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_26,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_27,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_28,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_29,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_30,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_52,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_53,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        },
        {
          photo: untitled_54,
          caption: text["our-product.snap-bottom-containers-with-rsc-top"],
          subcaption: text["our-product.snap-bottom-containers-with-rsc-top"],
        }
      ]
    },
    {
      photo: untitled_103,
      caption: text[ "our-product.paper-corner"],
      data: [
        {
          photo: untitled_103,
          caption: text[ "our-product.paper-corner"],
          subcaption: text[ "our-product.paper-corner"],
        },
        {
          photo: untitled_104,
          caption: text[ "our-product.paper-corner"],
          subcaption: text[ "our-product.paper-corner"],
        },
        {
          photo: untitled_105,
          caption: text[ "our-product.paper-corner"],
          subcaption: text[ "our-product.paper-corner"],
        }
      ]
    },    
    {
      photo: untitled_106,
      caption: text["our-product.partition"],
      data: [
        {
          photo: untitled_106,
          caption: text["our-product.partition"],
          subcaption: text["our-product.partition"],
        },
        {
          photo: untitled_107,
          caption: text["our-product.partition"],
          subcaption: text["our-product.partition"],
        },
        {
          photo: untitled_108,
          caption: text["our-product.partition"],
          subcaption: text["our-product.partition"],
        },
        {
          photo: untitled_109,
          caption: text["our-product.partition"],
          subcaption: text["our-product.partition"],
        },
        {
          photo: untitled_111,
          caption: text["our-product.partition"],
          subcaption: text["our-product.partition"],
        },
        {
          photo: untitled_112,
          caption: text["our-product.partition"],
          subcaption: text["our-product.partition"],
        }
      ]
    },
    {
      photo: untitled_55,
      caption: text["our-product.paper-core"],
      data: [
        {
          photo: untitled_55,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_56,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_57,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_58,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_59,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_61,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_62,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_63,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_64,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_65,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_66,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_67,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_68,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_69,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_70,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_71,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_72,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_73,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_74,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_75,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_76,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_77,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_78,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_79,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_80,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_81,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_82,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_83,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_84,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_85,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_86,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_87,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_88,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_89,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_90,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_91,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_92,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_93,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_94,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_95,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_96,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_97,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_98,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_99,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        },
        {
          photo: untitled_100,
          caption: text["our-product.paper-core"],
          subcaption: text["our-product.paper-core"],
        }
      ]
    },
    {
      photo: untitled_41,
      caption: text["our-product.regular-slotted-container-rsc"],
      data: [
        {
          photo: untitled_41,
          caption: text["our-product.regular-slotted-container-rsc"],
          subcaption: text["our-product.regular-slotted-container-rsc"],
        },
        {
          photo: untitled_42,
          caption: text["our-product.regular-slotted-container-rsc"],
          subcaption: text["our-product.regular-slotted-container-rsc"],
        },
        {
          photo: untitled_43,
          caption: text["our-product.regular-slotted-container-rsc"],
          subcaption: text["our-product.regular-slotted-container-rsc"],
        },
        {
          photo: untitled_44,
          caption: text["our-product.regular-slotted-container-rsc"],
          subcaption: text["our-product.regular-slotted-container-rsc"],
        },
        {
          photo: untitled_45,
          caption: text["our-product.regular-slotted-container-rsc"],
          subcaption: text["our-product.regular-slotted-container-rsc"],
        }
      ]
    },
    {
      photo: untitled_113,
      caption: text["our-product.half-slotted-container-hsc"],
      data: [
        {
          photo: untitled_113,
          caption: text["our-product.half-slotted-container-hsc"],
          subcaption: text["our-product.half-slotted-container-hsc"],
        },
        {
          photo: untitled_114,
          caption: text["our-product.half-slotted-container-hsc"],
          subcaption: text["our-product.half-slotted-container-hsc"],
        },
        {
          photo: untitled_115,
          caption: text["our-product.half-slotted-container-hsc"],
          subcaption: text["our-product.half-slotted-container-hsc"],
        }
      ]
    },
    {
      photo: untitled_31,
      caption: text["our-product.die-cut-box4"],
      data: [
        {
          photo: untitled_31,
          caption: text["our-product.die-cut-box4"],
          subcaption: text["our-product.die-cut-box4"],
        },
        {
          photo: untitled_32,
          caption: text["our-product.die-cut-box4"],
          subcaption: text["our-product.die-cut-box4"],
        },
        {
          photo: untitled_33,
          caption: text["our-product.die-cut-box4"],
          subcaption: text["our-product.die-cut-box4"],
        },
        {
          photo: untitled_34,
          caption: text["our-product.die-cut-box4"],
          subcaption: text["our-product.die-cut-box4"],
        },
        {
          photo: untitled_35,
          caption: text["our-product.die-cut-box4"],
          subcaption: text["our-product.die-cut-box4"],
        },
        {
          photo: untitled_36,
          caption: text["our-product.die-cut-box4"],
          subcaption: text["our-product.die-cut-box4"],
        }
      ]
    }
  ];
  const cliclkPhoto = (inputData) => {
    setIsOpen(true);
    setPhoto(inputData);
  };
  const element_servicedata = servicesData.map((services, index) => (
    <div
      className="col-lg-12 col-md-12 col-sm-12 " //col-lg-3 col-md-6 col-sm-6
      key={index}
      onClick={() => cliclkPhoto(services.data)}
    >
      <div className="single-services">
        <img alt="service" src={services.photo} />
        <p>
          <b>{services.caption}</b>
        </p>
      </div>
    </div>
  ));
  return (
    <React.Fragment>
      <div></div>
      <section id="services" className="services-area ptb-100">
        <ReactBnbGallery
          show={isOpen}
          photos={photo}
          onClose={() => setIsOpen(false)}
        />
        <div className="container">
          <div className="section-title">
            <h2 className="header">
              <b>{text["our-product.header"]}</b>
            </h2>
            <p>{text["our-product.detail"]}</p>
          </div>
          <div className="row">
            <OwlCarousel
              className="team-slides owl-theme-default"
              loop={true}
              autoplay={true}
              nav={false}
              mouseDrag={true}
              autoplayHoverPause={true}
              responsiveClass={true}
              dots={true}
              navText={[
                "<i class='icon icon-Arrow'></i>",
                "<i class='icon icon-Arrow'></i>",
              ]}
              responsive={{
                0: {
                  items: 1,
                },
                576: {
                  items: 2,
                },
                768: {
                  items: 3,
                },
                1200: {
                  items: 4,
                },
              }}
            >
              {element_servicedata}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
Services.propsTypes = {
  sectionTitle: PropTypes.string,
  sectionTitleSpan: PropTypes.string,
  sectionContent: PropTypes.string,
  sectionImage: PropTypes.string,
};
Services.defaultProps = {
  sectionImage: require("../../src/assets/img/360x270.jpg"),
   // new
  untitled_37: require("../../src/assets/img/untitled-37.jpg"),
  untitled_38: require("../../src/assets/img/untitled-38.jpg"),
  untitled_39: require("../../src/assets/img/untitled-39.jpg"),
  untitled_40: require("../../src/assets/img/untitled-40.jpg"),
  untitled_49: require("../../src/assets/img/untitled-49.jpg"),
  untitled_50: require("../../src/assets/img/untitled-50.jpg"),
  untitled_46: require("../../src/assets/img/untitled-46.jpg"),
  untitled_47: require("../../src/assets/img/untitled-47.jpg"),
  untitled_48: require("../../src/assets/img/untitled-48.jpg"),
  untitled_116: require("../../src/assets/img/untitled-116.jpg"),
  untitled_117: require("../../src/assets/img/untitled-117.jpg"),
  untitled_118: require("../../src/assets/img/untitled-118.jpg"),
  untitled_121: require("../../src/assets/img/untitled-121.jpg"),
  untitled_122: require("../../src/assets/img/untitled-122.jpg"),
  untitled_119: require("../../src/assets/img/untitled-119.jpg"),
  untitled_120: require("../../src/assets/img/untitled-120.jpg"),
  untitled_137: require("../../src/assets/img/untitled-137.jpg"),
  untitled_139: require("../../src/assets/img/untitled-139.jpg"),
  untitled_140: require("../../src/assets/img/untitled-140.jpg"),
  untitled_141: require("../../src/assets/img/untitled-141.jpg"),
  untitled_142: require("../../src/assets/img/untitled-142.jpg"),
  untitled_8: require("../../src/assets/img/untitled-8.jpg"),
  untitled_9: require("../../src/assets/img/untitled-9.jpg"),
  untitled_10: require("../../src/assets/img/untitled-10.jpg"),
  untitled_11: require("../../src/assets/img/untitled-11.jpg"),
  untitled_12: require("../../src/assets/img/untitled-12.jpg"),
  untitled_3: require("../../src/assets/img/untitled-3.jpg"),
  untitled_5: require("../../src/assets/img/untitled-5.jpg"),
  untitled_6: require("../../src/assets/img/untitled-6.jpg"),
  untitled_7: require("../../src/assets/img/untitled-7.jpg"),
  untitled_54: require("../../src/assets/img/untitled-54.jpg"),
  untitled_53: require("../../src/assets/img/untitled-53.jpg"),
  untitled_52: require("../../src/assets/img/untitled-52.jpg"),
  untitled_30: require("../../src/assets/img/untitled-30.jpg"),
  untitled_29: require("../../src/assets/img/untitled-29.jpg"),
  untitled_28: require("../../src/assets/img/untitled-28.jpg"),
  untitled_27: require("../../src/assets/img/untitled-27.jpg"),
  untitled_26: require("../../src/assets/img/untitled-26.jpg"),
  untitled_19: require("../../src/assets/img/untitled-19.jpg"),
  untitled_18: require("../../src/assets/img/untitled-18.jpg"),
  untitled_17: require("../../src/assets/img/untitled-17.jpg"),
  untitled_16: require("../../src/assets/img/untitled-16.jpg"),
  untitled_15: require("../../src/assets/img/untitled-15.jpg"),
  untitled_14: require("../../src/assets/img/untitled-14.jpg"),
  untitled_13: require("../../src/assets/img/untitled-13.jpg"),
  untitled_103: require("../../src/assets/img/untitled-103.jpg"),
  untitled_104: require("../../src/assets/img/untitled-104.jpg"),
  untitled_105: require("../../src/assets/img/untitled-105.jpg"),
  untitled_106: require("../../src/assets/img/untitled-106.jpg"),
  untitled_107: require("../../src/assets/img/untitled-107.jpg"),
  untitled_108: require("../../src/assets/img/untitled-108.jpg"),
  untitled_109: require("../../src/assets/img/untitled-109.jpg"),
  untitled_111: require("../../src/assets/img/untitled-111.jpg"),
  untitled_112: require("../../src/assets/img/untitled-112.jpg"),
  untitled_55: require("../../src/assets/img/untitled-55.jpg"),
  untitled_56: require("../../src/assets/img/untitled-56.jpg"),
  untitled_57: require("../../src/assets/img/untitled-57.jpg"),
  untitled_58: require("../../src/assets/img/untitled-58.jpg"),
  untitled_59: require("../../src/assets/img/untitled-59.jpg"),
  untitled_61: require("../../src/assets/img/untitled-61.jpg"),
  untitled_62: require("../../src/assets/img/untitled-62.jpg"),
  untitled_63: require("../../src/assets/img/untitled-63.jpg"),
  untitled_64: require("../../src/assets/img/untitled-64.jpg"),
  untitled_65: require("../../src/assets/img/untitled-65.jpg"),
  untitled_66: require("../../src/assets/img/untitled-66.jpg"),
  untitled_67: require("../../src/assets/img/untitled-67.jpg"),
  untitled_68: require("../../src/assets/img/untitled-68.jpg"),
  untitled_69: require("../../src/assets/img/untitled-69.jpg"),
  untitled_70: require("../../src/assets/img/untitled-70.jpg"),
  untitled_71: require("../../src/assets/img/untitled-71.jpg"),
  untitled_72: require("../../src/assets/img/untitled-72.jpg"),
  untitled_73: require("../../src/assets/img/untitled-73.jpg"),
  untitled_74: require("../../src/assets/img/untitled-74.jpg"),
  untitled_75: require("../../src/assets/img/untitled-75.jpg"),
  untitled_76: require("../../src/assets/img/untitled-76.jpg"),
  untitled_77: require("../../src/assets/img/untitled-77.jpg"),
  untitled_78: require("../../src/assets/img/untitled-78.jpg"),
  untitled_79: require("../../src/assets/img/untitled-79.jpg"),
  untitled_80: require("../../src/assets/img/untitled-80.jpg"),
  untitled_81: require("../../src/assets/img/untitled-81.jpg"),
  untitled_82: require("../../src/assets/img/untitled-82.jpg"),
  untitled_83: require("../../src/assets/img/untitled-83.jpg"),
  untitled_84: require("../../src/assets/img/untitled-84.jpg"),
  untitled_85: require("../../src/assets/img/untitled-85.jpg"),
  untitled_86: require("../../src/assets/img/untitled-86.jpg"),
  untitled_87: require("../../src/assets/img/untitled-87.jpg"),
  untitled_88: require("../../src/assets/img/untitled-88.jpg"),
  untitled_89: require("../../src/assets/img/untitled-89.jpg"),
  untitled_90: require("../../src/assets/img/untitled-90.jpg"),
  untitled_91: require("../../src/assets/img/untitled-91.jpg"),
  untitled_92: require("../../src/assets/img/untitled-92.jpg"),
  untitled_93: require("../../src/assets/img/untitled-93.jpg"),
  untitled_94: require("../../src/assets/img/untitled-94.jpg"),
  untitled_95: require("../../src/assets/img/untitled-95.jpg"),
  untitled_96: require("../../src/assets/img/untitled-96.jpg"),
  untitled_97: require("../../src/assets/img/untitled-97.jpg"),
  untitled_98: require("../../src/assets/img/untitled-98.jpg"),
  untitled_99: require("../../src/assets/img/untitled-99.jpg"),
  untitled_100: require("../../src/assets/img/untitled-100.jpg"),
  untitled_41: require("../../src/assets/img/untitled-41.jpg"),
  untitled_42: require("../../src/assets/img/untitled-42.jpg"),
  untitled_43: require("../../src/assets/img/untitled-43.jpg"),
  untitled_44: require("../../src/assets/img/untitled-44.jpg"),
  untitled_45: require("../../src/assets/img/untitled-45.jpg"),
  untitled_115: require("../../src/assets/img/untitled-115.jpg"),
  untitled_114: require("../../src/assets/img/untitled-114.jpg"),
  untitled_113: require("../../src/assets/img/untitled-113.jpg"),
  untitled_31: require("../../src/assets/img/untitled-31.jpg"),
  untitled_32: require("../../src/assets/img/untitled-32.jpg"),
  untitled_33: require("../../src/assets/img/untitled-33.jpg"),
  untitled_34: require("../../src/assets/img/untitled-34.jpg"),
  untitled_35: require("../../src/assets/img/untitled-35.jpg"),
  untitled_36: require("../../src/assets/img/untitled-36.jpg"),
};
const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
export default connect(mapStateToProps, {})(Services);
