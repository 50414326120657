import React, { useState } from "react";
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { connect } from "react-redux";
import { baseURL } from "../service/api";

const ContactUs = ({ text }) => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    tel: "",
    description: "",
  };
  const [state, setstate] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [successMsg, setsuccessMsg] = useState("");
  const handleForm = () => {
    setLoading(true);
    setsuccessMsg("");
    axios
      .post(
        baseURL + "sendContact/",
        { ...state },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        }
      )
      .then(function(response) {
        setLoading(false);
        if (response.data.status === "success") {
          setsuccessMsg(text["contact.send-conatct-success"]);
          setstate(initialState);
          document.getElementById("contactForm").reset();
        } else setsuccessMsg(text["contact.send-conatct-fail"]);
      })
      .catch(function(error) {
        setLoading(false);
        setsuccessMsg(text["contact.send-conatct-fail"]);
      });
  };
  const handleFields = (e) => {
    e.persist();
    setstate((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  return (
    <React.Fragment>
      <section id="contact" className="contact-area ptb-100">
        <div className="container">
          <div className="contact-header">
            <h3>
              <b>
                <span>{text["contact.header"]}</span>
              </b>
            </h3>
          </div>
          <ValidationForm
            id="contactForm"
            onSubmit={(e, formData, inputs) => {
              e.preventDefault();
              handleForm(formData);
            }}
          >
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <TextInput
                    name="firstName"
                    id="firstName"
                    required
                    successMessage=""
                    errorMessage={text["contact.please-enter-your-firstname"]}
                    className="form-control"
                    placeholder={text["contact.firstname-*"]}
                    autoComplete="off"
                    onChange={handleFields}
                    value={state.firstName}
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <TextInput
                    name="lastName"
                    id="lastName"
                    required
                    successMessage=""
                    errorMessage={text["contact.please-enter-your-lastname"]}
                    className="form-control"
                    placeholder={text["contact.lastname-*"]}
                    autoComplete="off"
                    onChange={handleFields}
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <TextInput
                    name="email"
                    id="email"
                    type="text"
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    errorMessage={{
                      required: text["contact.please-enter-your-email-address"],
                      pattern:
                        text["contact.please-enter-a-valid-email-address"],
                    }}
                    successMessage=""
                    className="form-control"
                    placeholder={text["contact.email-*"]}
                    autoComplete="off"
                    onChange={handleFields}
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="form-group">
                  <TextInput
                    name="tel"
                    id="tel"
                    type="tel"
                    required
                    successMessage=""
                    errorMessage={text["contact.please-enter-your-tel"]}
                    className="form-control"
                    placeholder={text["contact.tel-*"]}
                    autoComplete="off"
                    onChange={handleFields}
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="form-group">
                  <TextInput
                    name="description"
                    id="description"
                    multiline
                    placeholder={text["contact.description-*"]}
                    className="form-control"
                    required
                    successMessage=""
                    errorMessage={text["contact.please-write-your-description"]}
                    rows="5"
                    autoComplete="off"
                    onChange={handleFields}
                  />
                  <div className="help-block with-errors"></div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 text-right">
                <button type="submit" className="btn btn-submit">
                  {loading ? (
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    text["contact.submit"]
                  )}
                </button>
                <div id="msgSubmit" className="h3 hidden text-right">{successMsg}</div>
                <div className="clearfix"></div>
              </div>
            </div>
          </ValidationForm>
        </div>
      </section>
    </React.Fragment>
  );
};
const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
export default connect(mapStateToProps, {})(ContactUs);
