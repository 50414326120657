import React from "react";
const CardDetail = ({ header, detail }) => {
  return (
    <div className={`card-detail`}>
      <h5>
        <b>{header}</b>
      </h5>
      <p>{detail}</p>
    </div>
  );
};
CardDetail.defaultProps = {
  header: "",
  detail: "",
};
export default React.memo(CardDetail);
