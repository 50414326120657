import React from "react";
import CardCorrugatedPaper from "./CardCorrugatedPaperTypes";
import CardCorrugatedPaperGrades from "./CardCorrugatedPaperGrades";
import CardCorrugatedTypes from "./CardCorrugatedTypes";
const Collapse = () => {
  return (
    <div id="accordion">
      <CardCorrugatedPaper />
      <CardCorrugatedPaperGrades />
      <CardCorrugatedTypes />
    </div>
  );
};
Collapse.defaultProps = {
};
export default Collapse;