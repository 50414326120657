import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from "./redux/store";
import App from './App';
ReactDOM.render(
    <BrowserRouter basename={"/"}>
          {/* /tf-jsx-demo/exolot/ */}
          <Provider store={store}>
          <Route path="/" component={App} />
          </Provider>
        
    </BrowserRouter>, document.getElementById('root'));

