import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Icofont from "react-icofont";
const Certificate = ({ text, sectionImage }) => {
  return (
    <React.Fragment>
      <section id="certificate" className="certificate-area ptb-100">
        <div className="container">
          <div className="section-title">
            <h2 className="header">
              <b>{text["certificate.header"]}</b>
            </h2>
            <div>
              <img className="certificate-img" alt="pic" src={sectionImage} />
            </div>
          </div>

          <div className="certificate-detail">
            <p className="detail">{text["certificate.detail"]}</p>
            <p className="detail">
              <strong>{text["certificate.iso"]}</strong>
            </p>
          </div>
          {/* list */}
          <div className="certificate-list">
            <div className="row">
              <div className="col-sm-6 col-12">
                <p>
                  <Icofont
                    className="icon-checked"
                    size="1"
                    icon="icofont-checked"
                  />
                  {text["certificate.upper-customer-focus"]}
                </p>
              </div>
              <div className="col-sm-6 col-12">
                <p>
                  <Icofont
                    className="icon-checked"
                    size="1"
                    icon="icofont-checked"
                  />
                  {text["certificate.upper-leadership"]}
                </p>
              </div>
              <div className="col-sm-6 col-12">
                <p>
                  <Icofont
                    className="icon-checked"
                    size="1"
                    icon="icofont-checked"
                  />
                  {text["certificate.upper-system-approach"]}
                </p>
              </div>
              <div className="col-sm-6 col-12">
                <p>
                  <Icofont
                    className="icon-checked"
                    size="1"
                    icon="icofont-checked"
                  />
                  {text["certificate.upper-continual-improvement"]}
                </p>
              </div>
              <div className="col-sm-6 col-12">
                <p>
                  <Icofont
                    className="icon-checked"
                    size="1"
                    icon="icofont-checked"
                  />
                  {text["certificate.upper-involvement-of-people"]}
                </p>
              </div>
              <div className="col-sm-6 col-12">
                <p>
                  <Icofont
                    className="icon-checked"
                    size="1"
                    icon="icofont-checked"
                  />
                  {text["certificate.upper-process-approach"]}
                </p>
              </div>
              <div className="col-sm-6 col-12">
                <p>
                  <Icofont
                    className="icon-checked"
                    size="1"
                    icon="icofont-checked"
                  />
                  {text["certificate.upper-fact-based-decision-making"]}
                </p>
              </div>
              <div className="col-sm-6 col-12">
                <p>
                <Icofont
                        className="icon-checked"
                        size="1"
                        icon="icofont-checked"
                      />
                      {
                        text[
                          "certificate.upper-mutually-beneficial-supplier-relationship"
                        ]
                      }
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
Certificate.propTypes = {
  sectionImage: PropTypes.string,
};
Certificate.defaultProps = {
  sectionImage: require("../../src/assets/img/certificate.jpg"),
};
const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
export default connect(mapStateToProps, {})(Certificate);
