import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Page from "react-page-loading";
//Package CSS & JS
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template SCSS & CSS Style
import "./assets/css/style.scss";
import "./assets/css/responsive.scss";

//Component Import
import HomeOne from "./pages/HomeOne";
// import Home from "./pages/Home";
import { connect } from 'react-redux';

class App extends Component {
    render() {
        return (
            <div className="App">
                <div>
                    <Page loader={"bubble-spin"} color={"#71c21b"} size={9}>
                        <Switch >
                            <Route
                                path="/"
                                component={HomeOne}
                                toggleGallery={this.toggleGallery}
                            />
                        </Switch>
                    </Page>
                </div>
            </div>
        );
    }
}
const mapStateToProps = ({ language }) => {
    return { ...language };
}
export default withRouter(connect(mapStateToProps, {})(App));
