import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Collapse from "./collapse/index";
const About = (props) => {
  const { text } = props;
  return (
    <React.Fragment>
      <section id="about" className="about-area ptb-100 bg-f9f9f9">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="about-title">
                <h3>
                  <b>
                    <span>{text["about.header"]}</span>
                  </b>
                </h3>
                <h5>
                  {text["about.skill-part1"]}
                  <span>{text["about.skill-part2"]}</span>
                </h5>
                <p>{text["about.about-detail"]}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="p-picture-about">
                <img src={props.sectionImage} alt="about-img" />
              </div>
            </div>

            <div className="col-lg-7 col-md-12 col-sm-12 offset-lg-5">
              <div className="corrugated">
                <h3>
                  <b>
                    <span>{text["corrugated.header"]}</span>
                  </b>
                </h3>
                <p>{text["corrugated.detail"]}</p>
              </div>
            </div>

            <div className="col-sm-12">
              <Collapse />
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
About.propTypes = {
  sectionImage: PropTypes.string,
};
About.defaultProps = {
  sectionImage: require("../../src/assets/img/about.jpg"),
};
const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
export default connect(mapStateToProps, {})(About);
