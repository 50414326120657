import React, { Component } from "react";
import { Link } from "react-scroll";
import { Navbar, Nav, Container } from "react-bootstrap";
import LauageSelecter from "./LauageSelecter/index";
import { connect } from "react-redux";

class NavBar extends Component {
  state = {
    scrollWithOffset: null,
    logo: require("../../src/assets/img/jtp-logo1.png"),
    logoNav: require("../../src/assets/img/jtp-logo2.png"),
  };
  componentDidMount() {
    let elem = document.getElementById("navbar");
    let elem_start_background_logo = document.getElementById(
      "start-background-logo"
    );
    let elem_start_logo = document.getElementById("start-logo");
    let elem_nav_logo = document.getElementById("navbar-logo");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elem.classList.add("is-sticky");
        elem_start_background_logo.classList.add("none");
        elem_start_logo.classList.add("none");
        elem_nav_logo.classList.remove("none");
      } else {
        elem.classList.remove("is-sticky");
        elem_start_background_logo.classList.remove("none");
        elem_start_logo.classList.remove("none");
        elem_nav_logo.classList.add("none");
      }
    });
    let scrollWithOffset = (el, offset) => {
      const elementPosition = el.offsetTop - offset;
      window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth",
      });
    };
    this.setState({ scrollWithOffset });
  }

  closeNavbar() {
    if (window.matchMedia("screen and (max-width: 991px)").matches) {
      document.getElementById("collaspe-btn").click();
    }
  }
  render() {
    const { text } = this.props;
    return (
      <React.Fragment>
        <Navbar
          sticky="top"
          id="navbar"
          bg="light"
          expand="lg"
          className="navbar navbar-expand-lg navbar-light bg-light"
          collapseOnSelect={true}
        >
          <div id="start-background-logo" className="logo-background"></div>
          <div id="start-logo" className="logo">
            <img src={this.state.logo} alt="logo" />
          </div>
          <Container>
            <Navbar.Brand>
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={800}
              >
                <img
                  id="navbar-logo"
                  className="none"
                  src={this.state.logoNav}
                  alt="logo"
                  style={{ height: 53, width: 58 }}
                />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" id="collaspe-btn" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Item>
                  <Link
                    activeClass="active"
                    to="home"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    {text["navbar.home"]}
                  </Link>
                </Nav.Item>

                <Nav.Item>
                  <Link
                    activeClass="active"
                    to="about"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    {text["navbar.about"]}
                  </Link>
                </Nav.Item>

                <Nav.Item>
                  <Link
                    activeClass="active"
                    to="services"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    {text["navbar.product"]}
                  </Link>
                </Nav.Item>

                <Nav.Item>
                  <Link
                    activeClass="active"
                    to="certificate"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    {text["navbar.certificate"]}
                  </Link>
                </Nav.Item>

                <Nav.Item>
                  <Link
                    activeClass="active"
                    to="contact"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={800}
                    className="nav-link"
                    onClick={this.closeNavbar}
                  >
                    {text["navbar.contact-us"]}
                  </Link>
                </Nav.Item>
                <Nav.Item>
                  <LauageSelecter />
                </Nav.Item>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
export default connect(mapStateToProps, {})(NavBar);
