import React from "react";
import { connect } from "react-redux";
import { setLanguage } from "../../redux/actions.js";
const Emoji = props => (
  <option
    className="emoji"
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
    value={props.label}
  >
    {props.symbol}
  </option>
)
const LanguageSelector = (props) => {
  const changeLanguage = (event) => {
    props.setLanguage(event.target.value);
  };

  return (
    <div onChange={changeLanguage}>
      <select
        // className="custom-select"
        className=" btn btn-light dropdown-toggle"
        style={{ padding: 2 }}
      >
        <Emoji label="EN" symbol="🇺🇸" />
        <Emoji label="TH" symbol="🇹🇭" />
        <Emoji label="JP" symbol="🇯🇵" />
      </select>
    </div>
  );
};
export default connect(null, { setLanguage })(LanguageSelector);
