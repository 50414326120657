import React, { Component } from "react";
import OwlCarousel from "react-owl-carousel3";
import VisibilitySensor from "react-visibility-sensor";
import "react-modal-video/css/modal-video.min.css";
import { connect } from "react-redux";

class HomeSlides extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    //Home Slides loop start
    const homeslidesData = [
        {
          bgImgClass: "main-banner item-bg-one",
          part1: this.props.text["home.welcome"],
          part2: this.props.text["home.detail"]
        },
        {
          bgImgClass: "main-banner item-bg-two",
          part1: this.props.text["home.welcome"],
          part2: this.props.text["home.detail"]
        },
        {
          bgImgClass: "main-banner item-bg-three",
          part1: this.props.text["home.welcome"],
          part2: this.props.text["home.detail"]
        },
      ]
    const homeslidesdata = homeslidesData.map(
      (homeslides, index) => (
        <React.Fragment key={index}>
          <div className={homeslides.bgImgClass}>
            <div className="d-table">
              <div className="d-table-cell">
                <div className="container">
                  <VisibilitySensor>
                    {({ isVisible }) => (
                      <div className="hero-content">
                        <h5
                          className={
                            isVisible
                              ? "animated fadeInDown slow opacityOne"
                              : "opacityZero"
                          }
                        >
                          {homeslides.part1}
                        </h5>
                        <h1
                          className={
                            isVisible
                              ? "animated fadeInUp slow opacityOne"
                              : "opacityZero"
                          }
                        >
                          {homeslides.part2}
                        </h1>
                      </div>
                    )}
                  </VisibilitySensor>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    );
    //Home Slides END
    return (
      <React.Fragment>
        <div id="home">
          <OwlCarousel
            className="home-slides owl-theme"
            items={1}
            nav={true}
            dots={true}
            loop={true}
            touchDrag={false}
            mouseDrag={false}
            autoplay={true}
            autoplayHoverPause={true}
            animateOut={"slideOutDown"}
            animateIn={"slideInDown"}
            smartSpeed={700}
            autoplayTimeout={8000}
            navText={[
              "<i class='icofont-swoosh-right'></i>",
              "<i class='icofont-swoosh-right'></i>",
            ]}
          >
            {homeslidesdata}
          </OwlCarousel>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
export default connect(mapStateToProps, {})(HomeSlides);
