import React from "react";
const CardImageAndDetail = ({ sideImage, urlImage, header, detail }) => {
  return (
    <div
      className={`card-image ${
        sideImage === "left" ? "img-right column-reverse" : ""
      }`}
    >
      {sideImage === "left" ? (
        <>
          <div className="detail">
            <h5>
              <b>{header}</b>
            </h5>
            <p>{detail}</p>
          </div>
          <img src={urlImage} alt="detail" />
        </>
      ) : (
        <>
          <img src={urlImage} alt="detail" />
          <div className="detail">
            <h5>
              <b>{header}</b>
            </h5>
            <p>{detail}</p>
          </div>
        </>
      )}
    </div>
  );
};
CardImageAndDetail.defaultProps = {
  sideImage: "right",
  header: "",
  detail: "",
};
export default React.memo(CardImageAndDetail);
