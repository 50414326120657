import React from "react";
import { connect } from "react-redux";

const Footer = ({ text }) => {
  return (
    <React.Fragment>
      <div className="footer-address">
        <a href="mailto:sales@jtp1991.com">
          <span className="icon mr-5px">
            <i className={"icofont-ui-message"} />
          </span>
        </a>
        <a href="https://goo.gl/maps/urDyXCHVBf1of7jQ8">
          <span className="icon ml-5px">
            <i className={"icofont-location-pin"} />
          </span>
        </a>
        <p>{text["footer.tel"]}</p>
        <p>{text["footer.address"]}</p>
      </div>
      <footer className="footer-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>{text["footer.copyright"]}</p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};
const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
export default connect(mapStateToProps, {})(Footer);
