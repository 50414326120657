module.exports = {
  "navbar.home": "Home",
  "navbar.about": "About",
  "navbar.product": "Product",
  "navbar.certificate": "Certificate",
  "navbar.contact-us": "Contact Us",
  "home.welcome": "WELCOME TO J.T.P. (1991) CO., LTD.",
  "home.detail": "BEYOND PACKAGING",
  "welcome.header": "Welcome to J.T.P Co., Ltd.",
  "welcome.Introduction": `We specialize in manufacture of packing, with almost two decades of experience. 
In order to response to the increasing needs of customers, we have consult expanded 
our business, increased our production capacity as well as quality. We have achieved
ISO 9001:2015 and with our new factory up and running, we are able to produce, 
on a daily basis, over one hundred thousands of top quality packages, ranging from 
die-cut boxes, pad, partition, paper pallet and corner.`,
  "about.header": 'ABOUT',
  "about.skill-part1": "We Specialize In ",
  "about.skill-part2": "Manufacture Of Packing",
  "about.about-detail": `J.T.P. (1991) Co., Ltd. was established since 1991. We specialize in manufacture of Packing, 
with almost two decades of experience. In order to response to the increasing needs of customers, 
we have consultly expanded our business, increased our production capacity as well as quality. 
We have achieved ISO 9001 : 2015 and with our new factory up and running, we are able to produce, 
on a daily basis, over hundred thounsands of top quality packages, ranging from die-cut boxes, pad, 
partition, paper pallet and corner.`,
  "corrugated.header": "CORRUGATED PAPER",
  "corrugated.detail": `Our key raw material is corrugate paper, which we offer various faces, namely KA, KL, KI, CA, KS, and so on, 
with walls of different flutes, including BC, B, C, and E.`,
  "about.collapse.corrugated-paper-types": "CORRUGATED PAPER TYPES",
  "about.collapse.single-facer-board": "SINGLE FACER BOARD",
  "about.collapse.single-facer-board.detail": `Single face board consists of one sheet board attached to a corrugate wall. It is suitable for use in anti-shock packaging.`,
  "about.collapse.single-wall-board": "SINGLE WALL BOARD",
  "about.collapse.single-wall-board.detail": `Single wall board consists of 2 sheet boards with a corrugate wall in between. It is suitable for producing partition or paper pad, or die-cut box with medium strength for use as inner box.`,
  "about.collapse.double-wall-board": "Double Wall Board",
  "about.collapse.double-wall-board.detail": `Double wall board consists of 3 sheet boards with 2 corrugate walls, 
with the wall adjacent to the face being B flute for optimal printing while the C flute is preferred for the inner wall. 
It is suitable for producing anti-shock partition inserted in outer box such as regular slotted carton (RSC) for enhanced strength and weight durability.`,
  "about.collapse.corrugated-paper-grades": "CORRUGATED PAPER GRADES",
  "about.collapse.ca-or-m": "CA OR M",
  "about.collapse.ca-or-m.detail": `CA or M as Kraft paper for corrugated. Strength properties To prevent shocks Corrugated suits made of different sizes. 
As required The lowest price.`,
  "about.collapse.usability": "USABILITY",
  "about.collapse.ca-or-m.usability.detail": `Used for making corrugated style Inside the paper surface And a filter for blocking ads not to disparage each other in transit. Standard weight is 125 grams.`,
  "about.collapse.ks-or-kw": "KS OR KW",
  "about.collapse.ks-or-kw.detail": `KS or KW Kraft paper for surface box. This paper grades as the outer skin is white. Is strong and extraordinarily beautiful. Enhances the value of packaged goods.`,
  "about.collapse.ks-or-kw.usability.detail": `Commonly used to make packaging products, fruits, vegetables and flowers for export. Electric appliance Frozen seafood Enhances the value of packaged goods. But it was quite expensive Standard weight is 170 grams.`,
  "about.collapse.ki": "KI",
  "about.collapse.ki.detail": `KI is a Kraft paper grades for surface box. KI possesses the strength and durability of the medium. Suitable for packaging products in the country.`,
  "about.collapse.ki.usability.detail": `Commonly used in the packaging of various products , including appliances, glass, sanitary goods , and consumer prices are relatively cheap. Standard weights are 125 , 150, 185 gsm.`,
  "about.collapse.ka": "KA",
  "about.collapse.ka.detail": `KA is Kraft paper grades for surface box KA. The paper grades are outside surface is golden brown. Or dark brown Which possesses the strength and durability. 
  Some moisture , but not too much. Most commonly used to make packaging products for export.`,
  "about.collapse.ka.usability.detail": `Ideal for the production of packaging products of all kinds. Products are overweight or boxes to transport long distances. 
  You can overlay multiple layers Electronics. Electronic components, electronic circuit boards frozen seafood. Automotive Parts Including various consumer products. Standard weights are 125, 150 , 185, 230 gsm.`,
  "about.collapse.kl": "KL",
  "about.collapse.kl.detail": `KL is Kraft paper for surface box. A tone similar to a foreign paper. It is widely accepted in the international,Which possesses the strength and durability. 
  Color paper will be close to KI paper grades and paper grades KK.`,
  "about.collapse.kl.usability.detail": `Most commonly used to make packaging products for export. Ideal for the production of export goods of all kinds. Standard weights are 125, 150 175 205 gsm.`,
  "about.collapse.corrugated-types": "CORRUGATED TYPES",
  "about.collapse.e-flute": "E FLUTE",
  "about.collapse.e-flute.detail-part1": `The length of wave : 29-30/10 cm.`,
  "about.collapse.e-flute.detail-part2": `Wave Heights : 0.15 cm.`,
  "about.collapse.e-flute.usability.detail": `Suitable for use on a small box. Boxes and color printing The boxes for packaging of beauty products , such as children’s toy box. 
  Such input boxes , electronics and etc.`,
  "about.collapse.b-flute": "B FLUTE",
  "about.collapse.b-flute.detail-part1": `The length of wave : 15-16/10 cm.`,
  "about.collapse.b-flute.detail-part2": `Wave Heights : 0.25 cm.`,
  "about.collapse.usability.b-flute.detail": `Wave size are smaller than C flute. Suitable for use on Inner Box and not have much weight.`,
  "about.collapse.c-flute": "C FLUTE",
  "about.collapse.c-flute.detail-part1": "The length of wave : 13-14/10 cm.",
  "about.collapse.c-flute.detail-part2": "Wave Heights : 0.35 cm.",
  "about.collapse.c-flute.usability.detail": `C flute is the largest wave and most customers are using corrugated boxes in order than other flute.`,
  "about.collapse.bc-flute": "BC FLUTE",
  "about.collapse.bc-flute.detail-part1": `The length of wave : 15-16/10 cm. and 13-14/10 cm.`,
  "about.collapse.bc-flute.detail-part2": `Wave Heights : 0.25 cm. and 0.35 cm.`,
  "about.collapse.bc-flute.usability.detail": `Suitable for packing a lot of weight . Prevent product damage as well.`,
  "our-product.header": "OUR PRODUCT",
  "our-product.detail": `With our long-established expertise, we have in-depth knowledge and understanding about packaging
  that enables us to deliver the best packaging solutions. We have expanded production capacity and 
 constantly improve our quality to ensure customer satisfaction. JTP (1991) received the ISO 9001:2015 certification; 
 and our daily capacity of up to 100,000 pieces per day is made available to accommodate customer’s demands. 
 We offer a variety of product ranges, including die-cut boxes, partition, paper pallet, pad, and corner.`,
 "our-product.carton-box": "Carton Box",
 "our-product.paper-pallet": "Paper Pallet",
 "our-product.paper-tube": "Paper Tube",
 "our-product.paper-corner": "Paper Corner",
 "our-product.die-cut-box": "Die-Cut Box",
 "our-product.die-cut-box2": "Die-Cut Box",
 "our-product.die-cut-box3": "Die-Cut Box",
 "our-product.die-cut-box4": "Die-Cut Box",
 "our-product.partition": "Partition",
 "our-product.paper-core": "Paper Core",
 "our-product.snap-bottom-containers-with-rsc-top": "Snap Bottom Containers with RSC Top",
 "our-product.regular-slotted-container-rsc": "Regular Slotted Container (RSC)",
 "our-product.half-slotted-container-hsc": "Half Slotted Container (HSC)",
 "our-product.pizza-style": "Pizza Style",
 "our-product.cover-box": "Cover Box",
 "our-product.carton-box-with-paper-pallet": "Carton Box with Paper Pallet",
 "our-product.support-box": "Support",
 "our-product.subcaption-rsc": "RSC (Regular Slotted Container)",
 "our-product.subcaption-ftd": "FTD (Full Tele Scope Design Style Box)",
 "our-product.subcaption-die-cut": "Die-Cut Box",
 "our-product.subcaption-die-cut-box": "Die-Cut Box",
 "our-product.subcaption-partition-pad": "Partition, Pad",
 "our-product.subcaption-hand-made": "Hand Made",
 "our-product.subcaption-bigbox": "Big box with wooden pallets",
 "our-product.subcaption-paper-pallet": "Paper Pallet",
 "our-product.subcaption-paper-tube": "Paper Tube",
 "our-product.subcaption-paper-corner": "Paper Corner",
 "certificate.header": "CERTIFICATE",
 "certificate.detail": `The ISO 9001: 2015 is an international quality standard used to assess the ability 
 of a business to demonstrate commitment to quality and customer satisfaction.`,
 "certificate.iso": "ISO 9001: 2015 is based on 8 quality management principles",
 "certificate.upper-customer-focus": "CUSTOMER FOCUS",
 "certificate.upper-leadership": "LEADERSHIP",
 "certificate.upper-system-approach": "SYSTEM APPROACH",
 "certificate.upper-continual-improvement": "CONTINUAL IMPROVEMENT",
 "certificate.upper-involvement-of-people": "INVOLVEMENT OF PEOPLE",
 "certificate.upper-process-approach": "PROCESS APPROACH",
 "certificate.upper-fact-based-decision-making": "FACT BASED DECISION MAKING",
 "certificate.upper-mutually-beneficial-supplier-relationship": "MUTUALLY BENEFICIAL SUPPLIER RELATIONSHIP",
 "contact.header": "ContactUs",
 "contact.firstname-*": "Firstname *",
 "contact.please-enter-your-firstname": "Please enter your firstname",
 "contact.lastname-*": "Lastname *",
 "contact.please-enter-your-lastname": "Please enter your lastname",
 "contact.email-*": "Email *",
 "contact.please-enter-your-email-address": "Please enter your email address",
 "contact.please-enter-a-valid-email-address": "Please enter a valid email address",
 "contact.tel-*": "Tel *",
 "contact.please-enter-your-tel": "Please enter your tel",
 "contact.description-*": "Description *",
 "contact.please-write-your-description": "Please write your description",
 "contact.submit": "SUBMIT",
 "contact.send-contact-success": "Send contact success",
 "contact.send-contact-fail": "Send contact fail",
 "footer.tel": "Tel. 02-337-6644-46 #121-122",
 "footer.address": "33/2 Moo.1 Bangna - Trad Rd. KM.33, Bangpleenoi, Bangbo, Samutprakarn 10560",
 "footer.copyright": "Copyright © 2021 All Rights Reserved J.T.P (1991) CO., LTD."
};
