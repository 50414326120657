import React from "react";
import { connect } from 'react-redux';
const Welcome = ({ text }) => {
  return (
    <React.Fragment>
      <section className="welcome-area ptb-100">
        <div className="container">
          <div className="section-title">
            <h2 className='header'>
              <b>{text["welcome.header"]}</b>
            </h2>
            <p>{text["welcome.Introduction"]}</p>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
const mapStateToProps = ({ language }) => {
  const { text } = language;
  return { text };
};
export default connect(mapStateToProps, {})(Welcome);
