module.exports = {
    "navbar.home": "หน้าหลัก",
    "navbar.about": "เกี่ยวกับบริษัท",
    "navbar.product": "ผลิตภัณฑ์",
    "navbar.certificate": "นโยบายคุณภาพ",
    "navbar.contact-us": "ติดต่อเรา",
    "home.welcome": "ยินดีต้อนรับสู่ บริษัท เจ.ที.พี (1991) จำกัด",
    "home.detail": "BEYOND PACKAGING",
    "welcome.header": "ยินดีต้อนรับสู่ บริษัท เจ.ที.พี (1991) จำกัด",
    "welcome.Introduction": `ด้วยประสบการณ์เกือบสองทศวรรษ เราจึงเชี่ยวชาญด้านการผลิตบรรจุภัณฑ์ เพื่อตอบสนองความต้องการของลูกค้าที่เพิ่มขึ้น เราจึงขยายกิจการ เพิ่มกำลังการผลิตไปพร้อมกับการพัฒนาคุณภาพ เราได้รับ ISO 9001:2015 และด้วยโรงงานใหม่ของเรา ทำให้เราสามารถผลิตบรรจุภัณฑ์คุณภาพได้มากกว่าวันละแสนชิ้นได้แก่ die-cut boxes, partition, paper pallet, pad หรือ corner`,
    "about.header": 'เกี่ยวกับบริษัท',
    "about.skill-part1": "เราเชี่ยวชาญ ",
    "about.skill-part2": "ด้านการผลิตบรรจุภัณฑ์",
    "about.about-detail": `บริษัท เจ.ที.พี. (1991) จำกัดได้ก่อตั้งขึ้นเมื่อปีพุทธศักราช 2534 เรามีความเชี่ยวชาญในการผลิตบรรจุภัณฑ์ด้วยประสบการณ์กว่า 2 ทศวรรษ เพื่อเป็นการตอบสนองความต้องการที่เพิ่มขึ้นของลูกค้า เราจึงได้มีการขยายกิจการของเราขึ้น และเพิ่มกำลังการผลิต อีกทั้งด้านคุณภาพด้วยเช่นกัน เราได้รับการรับรองระบบ ISO 9001:2015 และจากการดำเนินกิจการพร้อมทั้งขยายขนาด จึงทำให้เราสามารถผลิตสินค้าได้มากกว่าแสนชิ้นด้วยคุณภาพสูงสุด สินค้าเรามีตั้งแต่ กล่องไดคัท แพ็ด แผ่นกั้น พาเลทลูกฟูก จนถึงแกนกระดาษ`,
    "corrugated.header": "กระดาษลูกฟูก",
    "corrugated.detail": `วัตถุดิบที่บริษัทนำมาผลิตได้มีการจำแนกออกเป็นประเภทต่างๆ อาทิ เช่น ประเภทกระดาษลูกฟูก, เกรดกระดาษและลอนกระดาษลูกฟูก`,
    "about.collapse.corrugated-paper-types": "ประเภทของการดาษลูกฟูก",
    "about.collapse.single-facer-board": "แผ่นลูกฟูก 2 ชั้น (Single Facer Board)",
    "about.collapse.single-facer-board.detail": `นิยมทำแผ่นกั้นหรือแผ่นรองห่อหุ้มผลิตภัณฑ์ต่างๆเพื่อกันกระแทกได้เป็นอย่างดี ประกอบไปด้วย กระดาษแผ่นเรียบ 1 แผ่น ประกบกับลอนลูกฟูก 1 แผ่น`,
    "about.collapse.single-wall-board": "แผ่นลูกฟูก 3 ชั้น (Single Wall Board)",
    "about.collapse.single-wall-board.detail": `นิยมนำมาทำแผ่นกั้นหรือแผ่นรอง กล่องไดคัท กล่องที่ไม่ต้องการความแข็งแรงมาก ใช้เป็นบรรจุภัณฑ์ชั้นใน Inner Box ประกอบไปด้วย กระดาษแผ่นเรียบ 2 แผ่น ประกบกับลอนลูกฟูก 1 แผ่น โดยลอนลูกฟูกจะอยู่ตรงกลางระหว่างกระดาษแผ่นเรียบทั้ง 2 แผ่น`,
    "about.collapse.double-wall-board": "แผ่นลูกฟูก 5 ชั้น (Double Wall Board)",
    "about.collapse.double-wall-board.detail": `นิยมทำแผ่นกั้นกันกระแทก เช่น RSC กล่องที่ต้องการรับน้ำหนักมากเป็นพิเศษ กล่องที่แข็งแรงเป็นพิเศษ ใช้เป็นบรรจุภัณฑ์ชั้นนอก Outer Box ประกอบไปด้วย กระดาษแผ่นเรียบ 3 แผ่น ประกบกับลอนลูกฟูก 2 แผ่น โดยกระดาษลอนลูกฟูกที่อยู่ติดกับผิวกล่องด้านนอกจะเป็นลอน 3 เพื่อประโยชน์ทางการพิมพ์ และกระดาษลอนลูกฟูกที่อยู่ด้านในจะเป็นลอน C เพื่อประโยชน์ทางด้านรับแรงกระแทก`,
    "about.collapse.corrugated-paper-grades": "เกรดกระดาษลูกฟูก",
    "about.collapse.ca-or-m": "CA หรือ M",
    "about.collapse.ca-or-m.detail": `CA หรือ M เป็นกระดาษคราฟท์สำหรับทำลอนลูกฟูก มีคุณสมบัติด้านความแข็งแรง ในการป้องกันแรงกระแทก เหมาะสมกับการทำลอนลูกฟูกขนาดต่างๆ ตามที่ต้องการ และมีราคาถูกสุด`,
    "about.collapse.usability": "การใช้งาน",
    "about.collapse.ca-or-m.usability.detail": `ใช้สำหรับทำลูกฟูกแบบต่างๆ กระดาษผิวกล่องด้านใน และใส้กรองสำหรับกั้นสินค้าไม่ให้กระทบกระแทกกันในขณะขนย้าย น้ำหนักมาตราฐานมี 125 แกรม`,
    "about.collapse.ks-or-kw": "KS170 หรือ KW170",
    "about.collapse.ks-or-kw.detail": `KS หรือ KW เป็นกระดาษคราฟท์สำหรับทำผิวกล่องเกรดนี้ เป็นกระดาษทำผิวด้านนอกเป็นสีขาว มีความแข็งแรงและสวยงามเป็นพิเศษ ช่วยเพิ่มคุณค่าของสินค้าที่บรรจุ`,
    "about.collapse.ks-or-kw.usability.detail": `นิยมใช้ในการทำกล่องบรรจุสินค้าประเภท ผัก ผลไม้และดอกไม้เพื่อการส่งออก เครื่องใช้ไฟฟ้า อาหารทะเลแช่แข็ง ช่วยเพิ่มคุณค่าของสินค้าที่บรรจุ แต่จะมีราคาค่อนข้างแพง น้ำหนักมาตราฐานมี 170 แกรม`,
    "about.collapse.ki": "KI125, 150, 185",
    "about.collapse.ki.detail": `KI เป็นกระดาษคราฟท์สำหรับทำผิวกล่องเกรด KI มีคุณสมบัติความแข็งแรงและความทนทานปานกลาง เหมาะสำหรับทำเป็นกล่องบรรจุสินค้าในประเทศ`,
    "about.collapse.ki.usability.detail": `นิยมใช้ในการทำกล่องบรรจุสินค้าต่างๆ ได้แก่ เครื่องใช้ไฟฟ้า เครื่องแก้ว ผ้าอนามัย สินค้าอุปโภคและบริโภค เป็นต้น ราคาค่อนข้างถูก น้ำหนักมาตราฐานมี 125, 150, 185 แกรม`,
    "about.collapse.ka": "KA125, 150, 185, 230",
    "about.collapse.ka.detail": `KA เป็นกระดาษคราฟท์สำหรับทำผิวกล่องเกรด KA โดยกระดาษเกรดนี้จะมีผิวด้านนอกเป็นสีน้ำตาลทอง หรือน้ำตาลเข้ม ซึ่งมีคุณสมบัติความแข็งแรงและความทนทาน กันความชื้นได้บ้างแต่ไม่มากนัก ส่วนใหญ่นิยมนำมาทำเป็นกล่องบรรจุสินค้าเพื่อการส่งออก`,
    "about.collapse.ka.usability.detail": `เหมาะสำหรับการผลิตกล่องบรรจุสินค้าทุกชนิด สินค้าที่มีน้ำหนักมากหรือกล่องที่ต้องขนส่งระยะทางไกลๆ สามารถวางทับกันได้หลายชั้น ได้แก่ เครื่องใช้ไฟฟ้า ชิ้นส่วนอิเล็คทรอนิคส์ แผงวงจรไฟฟ้า อาหารทะเลแช่แข็ง ชิ้นส่วนยานยนต์ รวมถึงสินค้าอุปโภคและบริโภคต่างๆ น้ำหนักมาตราฐานมี 125, 150, 185, 230 แกรม`,
    "about.collapse.kl": "KL125, KL150, KL175,KL205",
    "about.collapse.kl.detail": `KLเป็นกระดาษคราฟท์สีน้ำตาล สำหรับทำผิวกล่อง มีโทนสีใกล้เคียงกับกระดาษต่างประเทศ เป็นที่ยอมรับกันในสากล มีความแข็งแรงรองลงมาจาก เกรด KA และมีสีที่ใกล้เคียงกับเกรด KI และ KK`,
    "about.collapse.kl.usability.detail": `เหมาะกับการใช้ผลิตกล่องสำหรับสินค้าส่งออกทุกชนิด น้ำหนักมาตรฐาน: 125,150,175,205 กรัม/ตารางเมตร`,
    "about.collapse.corrugated-types": "ชนิดของลอนลูกฟูก",
    "about.collapse.e-flute": "ลอน E",
    "about.collapse.e-flute.detail-part1": `จำนวนลอนต่อความยาว : 29-30/10 ซม.`,
    "about.collapse.e-flute.detail-part2": `ความสูงลอน : 0.15 ซม.`,
    "about.collapse.e-flute.usability.detail": `เหมาะสำหรับใช้กับการทำกล่องขนาดเล็ก และกล่องพิมพ์สี ซึ่งเป็นกล่องสำหรับใช้บรรจุสินค้าประเภทเน้นความสวยงามของกล่อง เช่น กล่องของเล่นสำหรับเด็ก กล่องจำพวกใส่เครื่องใช้ไฟฟ้า ฯลฯ เป็นต้น`,
    "about.collapse.b-flute": "ลอน B",
    "about.collapse.b-flute.detail-part1": `จำนวนลอนต่อความยาว : 15-16/10 ซม.`,
    "about.collapse.b-flute.detail-part2": `ความสูงลอน : 0.25 ซม.`,
    "about.collapse.usability.b-flute.detail": `ลักษณะลอนจะเล็กกว่าลอน C`,
    "about.collapse.c-flute": "ลอน C",
    "about.collapse.c-flute.detail-part1": "จำนวนลอนต่อความยาว : 13-14/10 ซม.",
    "about.collapse.c-flute.detail-part2": "ความสูงลอน : 0.35 ซม.",
    "about.collapse.c-flute.usability.detail": `เป็นลอนที่ใหญ่ที่สุด ลูกค้าส่วนใหญ่จะใช้ลอนนี้ในการสั่งซื้อกล่องมากกว่าลอนอื่น`,
    "about.collapse.bc-flute": "ลอน BC",
    "about.collapse.bc-flute.detail-part1": `จำนวนลอนต่อความยาว : 15-16/10 ซม. และ 13-14/10 ซม.`,
    "about.collapse.bc-flute.detail-part2": `ความสูงลอน : 0.25 ซม. และ 0.35 ซม.`,
    "about.collapse.bc-flute.usability.detail": `ลอนนี้เหมาะสำหรับใช้บรรจุสินค้าที่มีน้ำหนักมาก ป้องกันสินค้าชำรุดเสียหายได้เป็นอย่างดี`,
    "our-product.header": "ผลิตภัณฑ์​ของเรา",
    "our-product.detail": `บริษัท เจ.ที.พี. (1991) จำกัด ได้ก่อตั้งขึ้นเมื่อปี พ.ศ. 2534 ด้วยประสบการณ์มากกว่าสองทศวรรษ เราจึงเชี่ยวชาญด้านการผลิตบรรจุภัณฑ์ เพื่อตอบสนองความต้องการของลูกค้าที่เพิ่มขึ้น เราจึงขยายกิจการ เพิ่มกำลังการผลิตไปพร้อมกับการพัฒนาคุณภาพ เราได้รับ ISO 9001:2015 และด้วยโรงงานใหม่ของเรา ทำให้เราสามารถผลิตบรรจุภัณฑ์คุณภาพได้มากกว่าวันละแสนชิ้นได้แก่ die-cutboxes, partition, paper pallet, pad หรือ corner`,
   "our-product.carton-box": "กล่องกระดาษ",
   "our-product.paper-pallet": "พาเลทกระดาษ",
   "our-product.paper-tube": "แกนกระดาษ",
   "our-product.paper-core": "แกนกระดาษ",
   "our-product.paper-corner": "กระดาษฉาก",
   "our-product.regular-slotted-container-rsc": "กล่องสี่ฝา",
   "our-product.half-slotted-container-hsc": "กล่องมีก้น ไม่มีฝา",
   "our-product.die-cut-box": "กล่องเสียบหัว เสียบท้าย",
   "our-product.die-cut-box2": "กล่องเกี่ยวก้น",
   "our-product.die-cut-box3": "กล่องก้นล็อคฝาเสียบ",
   "our-product.die-cut-box4": "กล่องฝาเสียบ",
   "our-product.snap-bottom-containers-with-rsc-top": "กล่องก้นขัดฝาเสียบ",
   "our-product.partition": "ไส้เสียบและพาทิชั่น",
   "our-product.pizza-style": "กล่องพิซซ่า",
   "our-product.cover-box": "กล่องฝาครอบ",
   "our-product.support-box": "กล่องซัพพอร์ต",
   "our-product.carton-box-with-paper-pallet": "กล่องชุดพาเลท",
   "our-product.subcaption-rsc": "กล่องสี่ฝา, กล่องฝาชน",
   "our-product.subcaption-ftd": "กล่องฝาครอบ",
   "our-product.subcaption-die-cut": "กล่องไดคัท",
   "our-product.subcaption-die-cut-box": "กล่องเสียบหัว เสียบท้าย",
   "our-product.subcaption-partition-pad": "แผ่นรอง,ไส้กล่อง",
   "our-product.subcaption-hand-made": "ประเภทงานฝีมือ",
   "our-product.subcaption-bigbox": "กล่องขนาดใหญ่ประกอบพาเลทไม้",
   "our-product.subcaption-paper-pallet": "พาเลทกระดาษ",
   "our-product.subcaption-paper-tube": "แกนกระดาษ",
   "our-product.subcaption-paper-corner": "กระดาษฉาก",
   "certificate.header": "นโยบายคุณภาพ",
   "certificate.detail": `บริษัท เจ.ที.พี. (1991) จำกัด ได้ให้ความสำคัญในเรื่องคุณภาพ เพื่อให้สอดคล้องกับนโยบายของบริษัทที่ว่า “มุ่งเน้นคุณภาพ สร้างความประทับใจในบริการ” และเพิ่มความมั่นใจให้กับลูกค้า`,
   "certificate.iso": "ISO 9001: 2015 เป็นไปตามหลักการจัดการคุณภาพ 8 ประการ",
   "certificate.upper-customer-focus": "มุ่งที่ลูกค้า",
   "certificate.upper-leadership": "ความเป็นผู้นำ",
   "certificate.upper-system-approach": "การมีส่วนร่วมของพนักงาน",
   "certificate.upper-continual-improvement": "การดำเนินงานเป็นกระบวนการ",
   "certificate.upper-involvement-of-people": "ความเป็นระบบในการบริหาร",
   "certificate.upper-process-approach": "ปรับปรุงอย่างต่อเนื่อง",
   "certificate.upper-fact-based-decision-making": "การใช้ข้อเท็จจริงเป็นมาตรฐานในการตัดสินใจ",
   "certificate.upper-mutually-beneficial-supplier-relationship": "ความสัมพันธ์กับผู้ส่งมอบบนผลประโยชน์เท่าเทียมกัน",
   "contact.header": "ติดต่อเรา",
   "contact.firstname-*": "ชื่อ *",
   "contact.please-enter-your-firstname": "กรุณากรอกชื่อของท่าน",
   "contact.lastname-*": "นามสกุล *",
   "contact.please-enter-your-lastname": "กรุณากรอกนามสกุลของท่าน",
   "contact.email-*": "อีเมล *",
   "contact.please-enter-your-email-address": "กรุณากรอกที่อยู่อีเมลของท่าน",
   "contact.please-enter-a-valid-email-address": "กรุณาใส่อีเมลที่ถูกต้อง",
   "contact.tel-*": "เบอร์โทร *",
   "contact.please-enter-your-tel": "กรุณากรอกเบอร์โทรของท่าน",
   "contact.description-*": "รายละเอียด *",
   "contact.please-write-your-description": "กรุณากรอกรายละเอียดของท่าน",
   "contact.submit": "ส่ง",
   "contact.send-contact-success": "การส่งข้อมูลเสร็จสิ้น",
   "contact.send-contact-fail": "การส่งข้อมูลล้มเหลว",
   "footer.tel": "โทร. 02-337-6644-46 #121-122",
   "footer.address": "33/2 หมู่ 1 ถนนบางนา-ตราด กม.33 ต.บางพลีน้อย อ.บางบ่อ จ.สมุทรปราการ 10560",
   "footer.copyright": "Copyright © 2021 All Rights Reserved J.T.P (1991) CO., LTD."
  };
  